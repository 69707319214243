import Vue from 'vue';
import VueRouter from 'vue-router';

import HomeView from '@/views/HomeView';
import PlatformView from '@/views/PlatformView';
import GameView from '@/views/GameView';
import GamePlayView from '@/views/GamePlayView';
import ProfileView from '@/views/ProfileView';
import TagView from "@/views/TagView";
import SubscribeView from "@/views/SubscribeView";
import Policy from "@/views/Policy";
import MobileView from "@/views/MobileView.vue";
import MobileIosView from "@/views/MobileIosView.vue";

Vue.use(VueRouter);

const routes = [
    // Home
    {path: '/platform/:platform', name: 'platform', component: PlatformView},
    {path: '/tag/:tag', name: 'tag', component: TagView},
    {path: '/game/:id', name: 'game', component: GameView},
    {path: '/play/:id', name: 'play', component: GamePlayView},
    {path: '/profile', name: 'profile', component: ProfileView},
    {path: '/info', name: 'policy', component: Policy},
    {path: '/mobile', name: 'policy', component: MobileView},
    {path: '/mobile-ios', name: 'policy', component: MobileIosView},
    {path: '/subscribe', name: 'subscribe', component: SubscribeView},
    {path: '/', name: 'home', component: HomeView},
];

const router = new VueRouter({
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    routes,
    mode: 'history'
});

router.beforeEach((to, from, next) => {
    /*window.auth = {
        msisdn: '7',
        subscribtion: 1
    }

    next();
    
    return;*/

    axios.get('/games/mix/profile').then(({data}) => {
        window.auth.authGot = true;

        if (data.msisdn) {
            window.auth.msisdn = data.msisdn;
            window.auth.name = data.name;
            window.auth.picture = data.picture;
            window.auth.subscribtion = data.subscription


            next();
        } else {
            window.auth.login_url = data.login_url
        }

        next();
    })
});

export default router;
