<template>
	<div>
		<div class="ios-items">
			<div class="ios-item">
				<p>1. Войдите на сайт https://mixxplay.ru в Safari</p>
			</div>
			
			<div class="ios-item">
				<p>2. Нажмите поделиться в нижней части экрана</p>

				<br>

				<img src="/img/ios2.png" alt="">
			</div>
			
			<div class="ios-item">
				<p>3. Выберите На экран "Домой"</p>

				<br>

				<img src="/img/ios33.png" alt="">
			</div>
			<div class="ios-item">
				<p>4. Укажите название для иконки</p>

				<br>

				<img src="/img/ios3.jpg" alt="">
			</div>
			
			<div class="ios-item">
				<p>5. Все готово! Теперь игры всегда будут под рукой!</p>

				<br>

				<img src="/img/ios1.jpg" alt="">
			</div>
		</div>
		
	</div>
</template>

<script>
export default {
	data() {
		return {
			
		}
	}
}
</script>


<style scoped>
.ios-item {
	padding: 30px;
	border: 1px solid #eee;
	margin-bottom: 20px;
	text-align: center;
	
	&:nth-child(odd) {
		background: rgba(243, 241, 241, 0.47);
	}
	
	p {
		margin-bottom: 15px;
		text-align: center;
		font-size: 18px;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
}
</style>