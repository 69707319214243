<template>
  <div class="footer-main" v-if="showFooter">
    <footer class="footer">
      <div class="wrapper">
        <div class="footer_row">
          <div class="footer_text">@ {{ year() }} MiXX Play</div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  methods: {
    year() {
      return moment().format('Y')
    }
  },
  computed: {
    showFooter() {
      return this.$route.name !== 'play';
    }
  },
}
</script>
