import { render, staticRenderFns } from "./GameView.vue?vue&type=template&id=17d5cf3d&scoped=true"
import script from "./GameView.vue?vue&type=script&lang=js"
export * from "./GameView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17d5cf3d",
  null
  
)

export default component.exports