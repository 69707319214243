<template>
	<div>
		<div class="wrapper">
			<div class="subscribe-form">
				<div class="subscribe-form_ico">
					<img src="/img/ico-phone.svg" width="55" alt="">
				</div>
				<div class="subscribe-form_text">
          Игра доступна по подписке MiXX Play. Оформите подписку, чтобы получить доступ к этой и другим играм на любой вкус
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				auth: window.auth,
				loading: false,
				smsError: false
			}
		},

		created() {
			if (this.auth.subscribtion || !this.auth.msisdn) {
				this.$router.push({
					name: 'home'
				})
			}
		},

		methods: {
			subscribe() {
				this.loading = true;
				
				this.axios.get('/games/subscribe').then(({data}) => {
					this.checkSubscribe();
				})
			},
			checkSubscribe() {
				axios.get('/games/mix/profile').then(({data}) => {
					this.smsError = data.sub_action_status == 1715 || data.sub_action_status == 1355;

					if (!data.sub_action_status) {
						this.loading = false;
						this.smsError = false;
						
						if (document.referrer) {
							history.back()
						} else {
							location.href = '/';
						}
					} else {
						setTimeout(() => {
							this.checkSubscribe();
						}, 1000);
					}
				})
			}
		},
	}
</script>

<style scoped>

</style>
