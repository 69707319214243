<template>
	<div>
		<div v-if="loading.all">
			<div class="game-block"
				 v-for="(category, key) in sortedCategories"
				 :key="'category' + category.id">
				<div v-if="!(isiOS() && category.name.search('Android') > -1)">
					<div class="wrapper">
						<div class="header-block">
							<h2 class="header-block_title bold">{{ category.name }}</h2>
						</div>

						<tab v-if="category.id !== 3" :headings="category.keys"
							 :active-key="Object.keys(category.keys)[0]">
							<template :slot="gamesKey" v-for="(games, gamesKey) in category.games">
								<div class="game-block_row">
									<div class="game-block_col" v-for="(game, index) in games">
										<cart :game="game" :index="index"></cart>
									</div>
								</div>
							</template>
						</tab>


						<div class="game-block_row" v-else>
							<div class="game-block_col" v-for="(game, index) in popularGames">
								<cart :game="game" :index="index"></cart>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="loading"></div>
	</div>
</template>

<script>
import Tab from "@/components/Tab/Tab";
import MainSlider from "@/components/MainSlider/MainSlider";
import MobileSlider from "@/components/MainSlider/MobileSlider";
import Cart from "@/components/Cart/Cart";

export default {
	components: {
		Tab,
		MainSlider,
		MobileSlider,
		Cart
	},

	data() {
		return {
			categories: {},
			popularGames: [],
			loading: {
				all: false,
				_key: 0
			},
		}
	},

	computed: {
		sortedCategories() {
			if (!this.loading.all) return {};

			let categories = {};

			for (let cat in this.categories) {
				categories[this.categories[cat].key] = this.categories[cat];
			}

			return categories;
		}
	},

	created() {
		this.axios.get('/games/categories/').then(({data}) => {
			if (!this.categories)
				this.categories = {};

			data.forEach((item, key) => {
				this.categories[item.id] = {
					id: item.id,
					name: item.name,
					games: null,
					key: key
				}

				this.initGames(item.id);
			});
		})
	},

	methods: {
		initGames(id) {
			if (id === 3) {
				let url = '/games/?categories=' + id + '&limit=1000';
				
				
				if (this.isiOS()) {
					url += '&platforms=2';
				}
				
				this.axios.get(url).then(({data}) => {
					this.popularGames = data.data;

					this.loading._key++;

					if (this.loading._key === Object.keys(this.categories).length) {
						this.loading.all = true;
					}
				})
			} else {
				let url = '/games/?categories=' + id + '&limit=1000&rank=5';


				if (this.isiOS()) {
					url += '&platforms=2';
				}
				
				this.axios.get(url).then(({data}) => {
					this.categories[id].games = this.sortGames(data.data);
					this.categories[id].keys = this.setKeys(Object.keys(this.categories[id].games));

					this.loading._key++;

					if (this.loading._key === Object.keys(this.categories).length) {
						this.loading.all = true;
					}
				})
			}
		},

		sortGames(games) {
			let gamesObj = {};

			games.forEach((game) => {
				if (!gamesObj[game.genre_name]) {
					gamesObj[game.genre_name] = [game];
				} else {
					gamesObj[game.genre_name].push(game)
				}
			});

			return gamesObj;
		},

		setKeys(keys) {
			let obj = {};

			for (let item in keys) {
				obj[keys[item]] = keys[item];
			}

			return obj;
		},
		isiOS() {
			return /iPad|iPhone|iPod/.test(navigator.userAgent);
		}
	},
}
</script>
