<template>
	<div v-if="loaded">
		<div class="profile">
			<div class="wrapper">
				<div class="header-block">
					<h2 class="header-block_title bold">Личный кабинет</h2>
				</div>
				
				<div class="profile_row">
                    <div class="profile_col">
                        <div class="profile_avatar">
                            <div class="profile_label-2">Аватар<span>*</span></div>
                            <img v-if="profileData.checkedImage" :src="profileData.checkedImage.image" alt="">
                            <a href="" class="profile_avatar_link" @click.prevent="showForm = true">
                                <div v-if="profileData.checkedImage">Изменить</div>
                                <span v-else>Выбрать</span>
                            </a>
                        </div>
                        
                        <div class="profile_images-block" v-if="showForm">
                            <div class="profile_images_title">Настройки аватара</div>
                            
                            <div class="profile_images" v-if="profileData.images">
                                <div class="profile_image" v-for="image in profileData.images" @click="checkImage(image)" :class="{active: profileData.tempImage.name === image.name}">
                                    <img :src="image.image" alt="">
                                </div>
                            </div>
                            
                            <div class="profile_images_button">
                                <div class="profile_images_button-item">
                                    <a data-v-59f8aca4="" href="" class="button __type-6" @click.prevent="saveImage">Выбрать</a>
                                </div>
                                <div class="profile_images_button-item">
                                    <a data-v-59f8aca4="" href="" class="button __type-5" @click.prevent="showForm = false">Отменить</a>
                                </div>
                            </div>
                        </div>
                    </div>
					<div class="profile_col">
						<label class="profile_block">
							<span class="profile_number">Номер телефона: {{ formatPhone(profileData.msisdn) }}</span>
							<span class="profile_sub">
                                Подписка "<strong>MiXX Play</strong>": 
                                <i v-if="subscription === 0" style="color: #a9a9a9;">Не подключена</i>
                                <i v-else-if="subscription === 1" style="color: #41a674;">Активна</i>
                                <i v-else-if="subscription === 2" style="color: #ff002a;">Заблокирована</i>
                                <i v-else-if="subscription === 3" style="color: #ff002a;">Отключена</i>
                            </span>
						</label>
						<label class="profile_block">
							<input type="text" class="profile_input" @input="profileData.name = $event.target.value" :value="profileData.name">
							<span class="profile_label" :class="{ active: profileData.name }">Имя<span>*</span></span>
						</label>
						<label class="profile_block">
							<input type="text" class="profile_input"  @input="profileData.nickname = $event.target.value" :value="profileData.nickname">
							<span class="profile_label" :class="{ active: profileData.nickname }">Ник</span>
						</label>
					</div>
				</div>
				
				
				<div class="profile_button">
					<a href="" class="button __type-4" @click.prevent="sendProfile">Сохранить</a>
				</div>
				
				<div class="profile_info" v-if="showInfo">Данные сохранены</div>
				<div class="profile_info __error" v-if="showError">
                    <span v-if="!profileData.name">Не заполнено имя</span>
                    <span v-else-if="!profileData.checkedImage">Не выбран аватар</span>
                    <span v-else>Ошибка, проверьте данные</span>
                </div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				auth: window.auth,
				
				profileData: {
					name: window.auth.name ? window.auth.name : '',
					msisdn: window.auth.msisdn ? window.auth.msisdn : '',
					nickname: window.auth.nickname ? window.auth.nickname : '',
					images: [],
					checkedImage: '',
					tempImage: ''
				},
				showForm: false,
				showInfo: false,
				showError: false,
                subscription: false,
                loaded: false
			}
		},
		created() {
			if (!this.auth.msisdn) {
				location.href = '/'
			}
			
			this.axios.get('/games/pictures/').then(({data}) => {
				this.profileData.images = data;
				
				data.forEach((image) => {
					if (image.image === this.auth.picture) {
						this.profileData.checkedImage = image;
						this.profileData.tempImage = image;
					}
				})
			});

			this.axios.get('/games/mix/profile').then(({data}) => {
                this.loaded = true;
				this.profileData.name = data.name;
				this.profileData.nickname = data.nickname;
				this.subscription = data.subscription;
			})
		},
		
		methods: {
			checkImage(image) {
				this.profileData.tempImage = image;
			},
			sendProfile() {
				this.showInfo = false;
				this.showError = false;
				
				if (this.profileData.name) {
					this.axios.post('/games/mix/profile', {
						"name": this.profileData.name,
						"nickname": this.profileData.nickname,
						"picture": this.profileData.checkedImage ? this.profileData.checkedImage.name : '' 
					}, {
						headers: {'Content-Type' : 'application/json'}
					}).then(() => {
						this.showInfo = true;
					}).catch(() => {
						this.showError = true;
					})
				} else {
					this.showError = true;
				}
			},
			saveImage() {
				if (this.profileData.tempImage) {
					this.profileData.checkedImage = this.profileData.tempImage;
					
					this.showForm = false;
				}
			},
			
			formatPhone(str) {
				return str.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,"$1 $2 $3 $4 $5");
			}
		}
	}
</script>

<style scoped>

</style>
