<template>
	<div v-if="showHeader">
		<header class="header">
			<div class="wrapper">
				<div class="header_row">
					<router-link :to="{name: 'home'}" class="header_name">
						<img src="/img/logo.svg">
					</router-link>

					<div v-if="auth.authGot">
						<a href="" v-if="!auth.msisdn" @click.prevent="authUser" class="header_btn-auth">Войти</a>
						<div v-else class="header_top">
							<a href="" class="header_btn-user" @click.prevent="showAuthForm = !showAuthForm"></a>

							<div class="header_links" v-if="showAuthForm">
								<div @click="showAuthForm = false">
									<router-link to="/profile">Личный кабинет</router-link>
								</div>
								<a href="" @click.prevent="logout">Выйти</a>
							</div>
						</div>
					</div>

					<div class="header_ico" @click="showMenu = true"><i></i><i></i><i></i></div>
				</div>
			</div>
		</header>

		<div class="menu">
			<div class="wrapper">
				<div class="menu_list">
					<router-link class="only-iphone" v-if="!isiOS()" to="/platform/android">Android</router-link>
					<router-link to="/platform/online">Онлайн</router-link>
					<router-link to="/mobile">Мобильное приложение</router-link>
				</div>
			</div>
		</div>

		<div class="header_menu" v-if="showMenu">
			<div class="header_menu-top">
				<div class="header_menu-name"></div>
				<div class="header_menu-close" @click="showMenu = false"></div>
			</div>

			<div class="header_menu-data">
				<ul>
					<li class="header_menu-item" v-if="!isiOS()">
						<router-link exact class="a" to="/platform/android" @click.native="showMenu = false">
							<span @click="showMenu = false">Android</span>
						</router-link>
					</li>
					<li class="header_menu-item">
						<router-link exact class="a" to="/platform/online" @click.native="showMenu = false">
							<span @click="showMenu = false">Онлайн</span>
						</router-link>
					</li>
					<li class="header_menu-item">
						<a v-if="!isiOS()" href="" class="a" @click.prevent="download" @click="showMenu = false">
							<span>Мобильное приложение</span>
						</a>
						<router-link to="/mobile-ios" class="a" v-else @click.native="showMenu = false">
							<span>Мобильное приложение</span>
						</router-link>
					</li>
					<a v-if="auth.authGot" href="" @click.prevent="logout">Выйти</a>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'underscore';
import $ from 'jquery';

export default {
	data() {
		return {
			auth: window.auth,
			showAuthForm: false,
			showMenu: false,
			showIosForm: false
		}
	},


	created() {

	},

	mounted() {

	},

	computed: {
		showHeader() {
			return location.pathname !== '/platform/mixx' && this.$route.name !== 'play';
		}
	},

	methods: {
		authUser() {
			localStorage.setItem('url', window.location.pathname);

			location.href = window.auth.login_url;
		},
		isiOS() {
			return /iPad|iPhone|iPod/.test(navigator.userAgent);
		},
		logout() {
			this.axios.get('/games/logout').then(() => {
				location.href = 'http://sso.tele2.ru/auth/realms/tele2-b2c/protocol/openid-connect/logout?redirect_uri=https://mixxplay.ru';
			})
		},
		checkUnsubscribe() {
			axios.get('/games/profile').then(({data}) => {
				if (!data.subscription) {
					location.reload();
				} else {
					setTimeout(() => {
						this.checkUnsubscribe();
					}, 500);
				}
			})
		},
		download() {
			location.href = '/MiXX_Play_1.12.apk'
		}
	}
}
</script>
