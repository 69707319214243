<template>
  <div class="mobile-slider">
    <div class="mobile-slider_image" style="background-image: url(/img/b4.jpg)"></div>

    <div class="mobile-slider_content">
      <div class="mobile-slider_title">King of Thieves</div>
      <div class="mobile-slider_button">
        <router-link to="/game/96" class="button __type-1 __min">Играть</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
