<template>
    <div>
        <div class="mobile-view">
            <img :src="'/img/mobile-view.png'" alt="">
            
            <div class="mobile-view_buttons">
                <router-link to="/mobile-ios">iOS</router-link>
                <a :href="'/MiXX_Play_1.12.apk'">Android</a>
            </div>
        </div>
        
        <div class="mobile-view_popup" v-if="showPopup">
            Как добавить иконку приложения на экран iPhone <br><br>
            
            Добавьте иконку на экран<br><br>
            
            Игры будут всегда под рукой!<br><br>
            
            1. Войдите на сайт <a href="https://mixxplay.ru/" target="_blank">https://mixxplay.ru/</a> в Safari<br>
            
            2. Нажмите Поделиться в нижней части экрана<br>
            
            3. Выберите На экран «Домой»<br>
            
            <div class="mobile-view_popup_close" @click="showPopup = false"></div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showPopup: false
        }
    }
}
</script>


<style scoped>
</style>